import api from "@/api";

export default {
    state:{
        notes: [],
        noteFiles: [],
        noteFilesId: null,
    },
    getters:{
        notes: (state) => state.notes,

        noteFiles: (state) => state.noteFiles,

        noteFilesId: (state) => state.noteFilesId,
    },
    mutations:{
        setNotes: (state, data) =>{
            state.notes = data;
        },

        setNoteFiles: (state, data) => {
            state.noteFiles = data;
        },

        setNoteFilesId: (state, data) => {
            state.noteFilesId = data;
        },
    },
    actions:{
        getNotes: async ({commit}, id) =>{
            try{
                const response = await api({requiresAuth: true}).get(
                    `v1/customer-notes/${id}`
                )
                const {data} = response.data;
                commit("setNotes", data);
                return data;
            }catch (err){
                console.log(err);
                return null;
            }
        }, 

        createNotes: async(
            {dispatch},
            {
                title,
                agent_id,
                customer_id,
                priority_id,
                deadline,
                description,
            }
        ) => {
            try{
                const response = await api({requiresAuth: true}).post(
                    "v1/notes",{ 
                        title,
                        agent_id,
                        customer_id,
                        priority_id,
                        deadline,
                        description,
                    }
                );

                const newNote = response.data.data;

                const noteId = newNote.id;

                await dispatch("getNotes", customer_id);

                return noteId;
            }catch(err){
                console.log(err)
                return null;
            }
        },

        deleteNotes: async({dispatch}, {noteId, customerId}) =>{
            try{
                await api({requiresAuth: true}).delete(
                    `v1/notes/${noteId}`
                );

                await dispatch("getNotes", customerId);
            }catch(err){
                console.log(err);
                return null;
            }
        },

        addNotesFile: async (_, {noteId, files}) => {
            const formData = new FormData();

            files.forEach((file)=>{
                formData.append("note_files[]", file);
            });
            try{
                await api({requiresAuth: true}).post(
                    `v1/notes/${noteId}/files`,
                    formData,
                    {
                        headers:{
                            "Content-Type": "multipart/form-data",
                        }
                    }
                ); 
            }catch(err){
                console.log(err);
                return null;
            }
        },

        updateNotes: async (
            {dispatch},
            {
                id,
                title,
                agent_id,
                customer_id,
                priority_id,
                deadline,
                description,
            }
        ) => {
            try{
                await api({requiresAuth: true}).patch(
                    `v1/notes/${id}`, {
                        title,
                        agent_id,
                        customer_id,
                        priority_id,
                        deadline,
                        description,
                    }
                );
                 dispatch("getNotes", customer_id);
            }catch(err){    
                console.log(err);
                return null;
            }
        },

        
        fetchNoteFiles: async ({ commit }, noteId) => { 
            try {
              const response = await api({ requiresAuth: true }).get(
                `/v1/notes/${noteId}`
              );
              const { data } = response.data;  
              commit("setFilesToEdit", data.files); 
              return data;
            } catch (err) {
              console.error("Error fetching note files:", err);
              return null;
            }
        },
    },
}