import { render, staticRenderFns } from "./Pendency.vue?vue&type=template&id=3b3624e1&scoped=true&"
import script from "./Pendency.vue?vue&type=script&lang=js&"
export * from "./Pendency.vue?vue&type=script&lang=js&"
import style0 from "./Pendency.vue?vue&type=style&index=0&id=3b3624e1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b3624e1",
  null
  
)

export default component.exports