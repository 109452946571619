<template> 
  <div class="policies">
    <div class="table">
      <div class="table__header box box--default">
        <div class="table__label table__label--medium table__label--fixed">
          Status
        </div>
        <div class="table__label table__label--large">Policennummer</div>
        <div class="table__label table__label--large">Erstellungsdatum</div> 
        <div class="table__label table__label--large">Versicherer</div>
        <div class="table__label table__label--large">Branche</div>
        <div class="table__label table__label--medium">Zuständig</div>
        <div
          class="table__label table__label--medium table__label--more-horizontal table__label--last"
        ></div>
      </div>
      <Loading v-if="detailsLoading" />
      <div v-else class="table__body">
        <div v-if="!computedPendencies.length" class="table__no-data">
          <NoData :type="'pendencies'" />
        </div>
      
        <div v-else class="table__policies"> 
          <Pendency
            v-for="pendency in computedPendencies"
            :key="pendency.id"
            :pendency="pendency"
          />
        </div>  
      </div>

      <!-- <Pagination
        :limit="3"
      :data="usersPerPage"
      @pagination-change-page="getResults"
    ></Pagination> -->
    </div>

    
  </div>

 
</template>

<script>
import Pendency from "@/components/AccountDetails/Pendency";
import Loading from "@/partials/Loading";
import NoData from "@/partials/NoData";
 
export default {
  name: "PendenciesTable",

  components: {
    Pendency,
    Loading,
    NoData, 
  },

  computed: {
    computedPendencies() {
      return this.$store.getters.pendencies;
    }
  },

  async mounted(){
     
    this.detailsLoading = true;
    try {
      await this.$store.dispatch("getPendencies", this.$route.params.id);
    } catch (error) {
      console.log('Error fetching pendencies:', error);
    } finally {
      this.detailsLoading = false;
    }
  },

  data(){
    return{
      detailsLoading: false,
      pendencies:[]
    }
  },

  props: {
 
  },
};
</script>
