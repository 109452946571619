<template>
  <div
    class="policy"
    :class="[
      !pendencyLoading ? `policy--${pendencyStatusColor}` : null,
      { 'policy--expanded': showMoreDetails },
    ]"    
  >

    <Loading v-if="pendencyLoading" />
    <template v-else>
      <div
        class="row row--policy box box--default"
        :class="{ 'row--active': showMoreDetails }"
      >
        <div
          class="row__data row__data--medium row__data--underline row__data--fixed"
        >
          <span @click="showStatusPopup = true">
            <transition name="fade" mode="in-out">
              <PopupEditer
                v-if="showStatusPopup"
                @togglePopup="showStatusPopup = $event"
                :items="this.status.name_de"
                :selected="this.status.name_de"
                :label="'name'"
                @submit="updatePolicyStatus($event)"
              />
            </transition>
            {{ this.status.name_de }}
          </span>
        </div>
        <div class="row__data row__data--large row__data "> 
          <p v-if="this.policy.policy_number !== null">
            {{ this.policy.policy_number  }} 
          </p>
          <p v-else>
            ----
          </p>
        </div> 

        <div class="row__data row__data--large row__data "> 
          <p>{{ formatDate(this.policy.created_at)}}</p> 
        </div>

      
        <div class="row__data row__data--large policy__company">
          
          <p>{{ company}}</p> 
        </div>
        <div class="row__data row__data--large">
          {{branch}}
        </div>
        <div class="row__data row__data--medium row__data--underline">
          <span @click="showAgentPopup = true">
            <transition name="fade" mode="in-out">
              <PopupEditer
                v-if="showAgentPopup"
                @togglePopup="showAgentPopup = $event"
                :items="agent"
                :selected="agent"
                :position="'left'"
                :label="['first_name', 'last_name']"
                @submit="updatePolicyAgent($event)"
              />
            </transition>
             
            {{agent}}
          </span>
        </div>
        <div
          class="row__data row__data--medium row__data--half row__data--last"
        >
          <div
            v-if="pendency.files.length"
            class="row__data row__data--small row__data--pdf icon__pdf--active" 
            @click="openPendencyFilesModal()"
          > 
          </div>
          <div v-else class="row__data row__data--small row__data--pdf icon icon__pdf" @click="openPendencyFilesModal()"></div>

          <div
            class="row__data row__data--small row__data--more" 
            :class="{ 'row__data--rotate': showMoreDetails }"
            @click="showMoreDetails = !showMoreDetails"
          >
          </div>
        </div>
      </div>
      <div
        class="policy__services"
        :class="{ 'policy__services--expand': showMoreDetails }"
      >
 
        <div
          class="services"
          ref="services"
          :style="
            showMoreDetails
              ? { maxHeight: `${manualHeight}px` }
              : { maxHeight: 0 }
          "
          :class="{ 'services--expand': showMoreDetails }"
        > 
          <div class="services__no-data" v-if="!pendency">
            No services found
          </div>
          <template v-else> 
            <div class="table__header table__header--inner">
              <div class="table__label table__label sender-label">
                Sender
              </div>
           
              <div class="table__label table__label beschreibung-label">
                Beschreibung
              </div> 

              <div class="table__label table__label deadline-reciever-label medium-label">
                Deadline / Reciever
              </div>

              <div class="table__label table__label status-label medium-label"> 
                Status
              </div>

              <div class="table__label table__label tools-label ">Tools</div> 

              <div class="table__label table__label dropdown-label"></div>
            </div>
  
            <PendencyService 
              :service="pendency"
              :replies="pendency.replies"
              :policy="pendency.policy"
              :agent = "pendency.agent"
              :created = "pendency.created_at"
              :description = "pendency.description"
              :deadline = "pendency.deadline"
              :receiver = "pendency.customer"
              :status = "pendency.status" 
              @toggle-replies="recalculateHeight"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
 import statics from "@/statics";
  import PendencyService from "@/components/AccountDetails/PendencyService";
 import PopupEditer from "@/partials/PopupEditer";
import Loading from "@/partials/Loading";
// import { formatDisplayDate } from "@/helpers/dateFormatters";
// import { fetchGlobalCustomer } from "@/helpers/accountDetailHelpers";
export default {
  name: "Pendency",

  components: {
     PendencyService,
     PopupEditer,
    Loading,
  },

  props: {
    pendency: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      showMoreDetails: false,
      showStatusPopup: false,
      showAgentPopup: false, 
      pendencyLoading: false, 
      manualHeight: 0, 
    };
  },

  computed: {
    company() {
      return this.policy.company
        ? this.policy.company.full_name || statics.empty
        : statics.empty;
    },

    branch() {
      if (this.policy.policy_services.length) {
        return this.policy.policy_services[0].service.branch_category.name || statics.empty; 
      }
      return null;
    },

    agent() {
      return this.policy.agent
        ? `${this.policy.agent.first_name || statics.empty} ${this.policy.agent
            .last_name || statics.empty}`
        : statics.empty;
    },



    services() {
      return this.pendency.pendencyService.length
        ? this.pendency.pendencyService
        : [];
    },

    servicesHeight() {
      if(this.$refs.services){
        return [...this.$refs.services.children].reduce(
          (acc, child) => acc + child.clientHeight,
          0
        );
      }else{
        return null;
      }
    },

    policy(){
      return this.pendency.policy
    },

    status(){
      return this.pendency.status
    },

    pendencyStatus() {
      return this.this.company.status
        ? this.this.company.status 
        : statics.empty;
    },
    pendencyStatusColor() {
      return this.policy.company.status
        ? statics.policyStatuses[
            statics.policyStatuses.findIndex(
              (status) => this.policy.company.status === status.id
            )
          ].name
        : null;
    },
  },
  watch: {
  servicesHeight(newValue) {
    this.servicesHeight = newValue;
  },
},


  mounted(){ 
    this.manualHeight = [...this.$refs.services.children].reduce(
      (acc, child) => acc + child.clientHeight,
        0
    ); 
  },

  methods: { 
    recalculateHeight() {
      this.$nextTick(() => {
        const height = this.$refs.services.scrollHeight;
        this.manualHeight = height; 
      });
    },

    formatDate(dateString) {
      if (!dateString) return '';  

      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');  
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },

    openPendencyFilesModal() {
      this.$store.commit("setShowFilesEditModal", true);
      this.$store.commit("setFilesToEditId", this.pendency.id);
      this.$store.commit("setFilesToEdit", this.pendency.files); 
      this.$store.commit("setFilesEditMode", 'Pendencies'); 
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  &__header {
    padding-left: 15px;
  }

  &__label {
    padding: 0;
    text-align: left;
  }
}

.sender-label{
  width: 22%;
}

.beschreibung-label{
  width: 35%;
}

.medium-label{
  width: 14%;
  text-align: center; 
}

.tools-label{
  width: 12%;
  text-align: center; 
}

.dropdown-label{
  width: 3%;
}
</style>
