<template>
<div class="policies">
    <div class="table">
        <div class="table__header box box--default">
            <div class="table__label table__label--medium table__label--fixed">
                Status
            </div>
            <div class="table__label table__label--large">Policennummer</div>
            <div class="table__label table__label--large">Erstellungsdatum</div>
            <div class="table__label table__label--large">Einreichdatum</div>
            <div class="table__label table__label--large">Versicherer</div>
            <div class="table__label table__label--large">Branche</div>
            <div class="table__label table__label--medium">Zuständig</div>
            <div class="table__label table__label--medium table__label--more-horizontal table__label--last"></div>
        </div>

        <div class="table__body">
            
            <Loading v-if="detailsLoading" />
            <template v-else>
                <div v-if="!computedNotes.length" class="table__no-data">
                  <NoData :type="'notes'" />
                </div>
                <template v-else>
                    <div class="table__policies">
                        <div v-for="note in computedNotes" :key="note.id" class="note-div" :class="[
                      {'animate__animated animate__zoomOutLeft': noteIsDeleting === note.id,},
                      { deleting: isNoteDeleted },
                    ]">

                            <div class="note-status">
                                <div class="note-status-color">
                                    <div 
                                      class="note-status-color-type" 
                                      :class="{
                                        'note-status-pending': note.priority.id === 1,
                                        'note-status-in-progress': note.priority.id === 2,
                                        'note-status-completed': note.priority.id === 3 
                                      }"
                                    ></div>
                                </div>
                                <div class="note-status-name">
                                    <p v-if="note.priority.id === 1">
                                        Hoch
                                    </p>
                                    <p v-if="note.priority.id === 2">
                                        Mittel
                                    </p>
                                    <p v-if="note.priority.id === 3">
                                        Niedrig
                                    </p>

                                    <p v-if="note.priority.id === 4">
                                        Priority: {{ note.priority.type }}
                                    </p>
                                </div>

                            </div>

                            <div class="note-owner">
                                <div class="note-img-wrapper" v-if="note.agent.photo">
                                    <img :src="require(`../../assets/icons/default/${note.agent.photo}`)">
                                </div>
                                <div class="note-img-wrapper no-img" v-else>
                                    <p>{{note.agent.first_name.charAt(0).toUpperCase() }} {{note.agent.last_name.charAt(0).toUpperCase() }}</p>
                                </div>

                                <p class="note-owner-name">{{note.agent.first_name }} {{note.agent.last_name }}</p>
                                <p class="note-owner-created">{{ formatDate(note.created_at) }}</p>
                            </div>

                            <div class=" note-actions-wrapper">
                                <div class="icon-wrapper">
                                    <img src="../../assets/icons/default/tick_icon_color.svg">
                                </div> 
                                 
                                <!-- <div class="icon icon__tick__color tool-icon"></div>-->
                              
                                <div
                                    v-if="note.files.length"
                                    class="icon icon__pdf--active tool-icon" 
                                    @click="openNoteFilesModal(note)"
                                >  
                                </div>
                                <div v-else class="icon icon__pdf tool-icon" @click="openNoteFilesModal(note)"></div>

                                <div class="icon icon__edit" @click="showEditNoteModal(note)"></div>
                                <div class="icon icon__delete tool-icon" :class="{ 'icon__delete--active': activeNoteId === note.id }" tabindex="-1" @focus="activeNoteId = note.id" @focusout="activeNoteId = null" :ref="'delete-' + note.id">
                                    <transition name="fade">
                                        <div class="delete" v-if="activeNoteId == note.id">
                                            <div class="delete__text">
                                                Are you sure you want to delete this note?
                                            </div>
                                            <div class="delete__buttons">
                                                <div class="button button--confirm" @click="deleteNote(note.id)"></div>
                                                <div class="button button--cancel" @click="closeNoteDeletePopup"></div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </div>

                            <div class="note-title">
                                <p>{{ note.title }}</p>
                            </div>
                            <div class="note-title-divider"></div>

                            <div class="note-description">
                                <p>{{ note.description }}</p>
                            </div>

                            <div class="note-deadline">
                                <p>Deadline: </p>
                                <p>{{ note.deadline }}</p>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</div>
</template>

<script> 
import Loading from "@/partials/Loading";
import NoData from "@/partials/NoData";
export default {
    name: "NotesTable",

    data() {
        return {
            noteIsDeleting: "",
            activeNoteId: null,
            isNoteDeleted: false,
        }
    },

    computed: {
        computedNotes() {
            return this.$store.getters.notes;
        }
    },
    components: {
        Loading,
        NoData,
    },

    props: {
        detailsLoading: {
            required: true,
            type: Boolean,
        },
    },

    async mounted() {
        this.detailsLoading = true;
        try {
            await this.$store.dispatch("getNotes", this.$route.params.id);
        } catch (error) {
            console.log('Error fetching notes:', error);
        } finally {
            this.detailsLoading = false;
        }
    },

    methods: {
        formatDate(dateString) {
            if (!dateString) return '';

            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}.${month}.${year}`;
        },

        closeNoteDeletePopup(noteId) {
            const deleteRef = this.$refs['delete-' + noteId];
            if (deleteRef && deleteRef[0]) {
                deleteRef[0].blur();
            }
            this.activeNoteId = null;
        },

        async deleteNote(id) {
            const noteId = id;
            const customerId = this.$route.params.id;
            this.noteIsDeleting = id;
            this.closeNoteDeletePopup();
            await this.$store.dispatch("deleteNotes", {
                noteId,
                customerId
            });
            this.isNoteDeleted = false;
        },

        showEditNoteModal(note) {
            this.$store.commit("setShowAddNotesModal", true);
            this.$store.commit("setIsNoteEditing", true);
            this.$store.commit("setEditingNoteData", note);
        },


        openNoteFilesModal(note) {   
            this.$store.commit("setShowFilesEditModal", true);
            this.$store.commit("setFilesToEditId", note.id);
            this.$store.commit("setFilesToEdit", note.files); 
            this.$store.commit("setFilesEditMode", 'Notes'); 
        },

    }
};
</script>

<style lang="scss" scoped>
.delete {
    position: absolute;
    z-index: 3;
    background: var(--bg-1);
    right: calc(3% + 5px);
    top: 25%;
    transform: translateY(-50%);
    width: 300px;
    display: flex;
    align-items: center;
    border-radius: var(--radius-1);
    border: 1px solid var(--border-2);
    padding: 10px 20px;
    box-shadow: 0px 3px 6px var(--shadow-7);

    &__text {
        line-height: 20px;
    }

    &__buttons {
        display: flex;
    }

    .button {
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: all 0.3s ease;

        &--confirm {
            background: url("../../assets/icons/default/confirm_icon.svg");
            margin: 0 15px;

            &:hover {
                background: url("../../assets/icons/default/confirm_hover_icon.svg");
            }
        }

        &--cancel {
            background: url("../../assets/icons/default/close_icon.svg");

            &:hover {
                background: url("../../assets/icons/default/close_hover_icon.svg");
            }
        }
    }
}

.note-div {
    width: 95%;
    box-shadow: 0px 0px 15px #0000001A;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 50px;
    position: relative;
}

.note-div:first-child {
    margin-top: 50px;
}

.icon-wrapper {
    width: 24px;
    height: 24px;
}

.icon-wrapper img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.note-actions-wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-end;
}

.note-title {
    width: 100%;
    margin-top: 10px;
}

.note-title p {
    color: #363636;
    font-size: 16px;
    font-weight: 600;
}

.note-title-divider {
    height: 1px;
    width: 160px;
    background-color: #EEEDED;
    margin: 10px 0;
}

.note-description {
    margin: 10px 0;
    max-width: 990px;
}

.note-description p {
    font-size: 14px;
    color: #363636;
    margin-bottom: 20px;
}

.note-deadline {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-right: 10px;
}

.note-deadline p {
    font-size: 16px;
    color: #7F8FA4;
}

.note-status {
    width: 140px;
    position: absolute;
    top: -20px;
    left: 0;
    background-color: #FFFFFF;
    display: flex;
    border-radius: 10px;
    align-items: center;
}

.note-status-name {
    flex-grow: 1;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    height: 28px;
    margin-left: -25px;
    z-index: 1;
    background-color: #FFFFFF;
    box-shadow: 0px 8px 10px #00000026;
}

.note-status-name p {
    margin-left: 20px;
    color: #7F8FA4;
    font-size: 14px;
}

.note-status-color {
    z-index: 2;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    box-shadow: 0px 8px 10px #00000026;
}

.note-status-color-type {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.note-status-pending {
    background-color: #DB2222;
}

.note-status-in-progress {
    background-color: #FF9E0F;
}

.note-status-completed {
    background-color: #B9C2CD;
}

.note-owner {
    position: absolute;
    top: 0%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -30%);
}

.note-img-wrapper {
    width: 46px;
    height: 46px;
    margin: 0 auto;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 8px 10px #00000026;
    border-radius: 50%;
}

.no-img {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    font-weight: 800;
}

.note-img-wrapper img {
    width: 100%;
    height: 100%;
}

.note-owner-name {
    margin: 10px 0;
    color: #363636;
    font-size: 14px;
    font-weight: bold;
}

.note-owner-created {
    color: #7F8FA4;
    font-size: 12px;
}
</style>
