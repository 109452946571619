<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel modal__panel--termination">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System </div> 
      </div>
 
      <div class="modal__main-icon modal__main-icon--pendencies"></div>
      <div class="modal__back" @click="goBack()"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form form--terminate">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>
            <div class="navigator__title">
              {{ $t(`addPendencyModal.${"title"}`) }}
            </div>
          </div>
        </div>
        <div class="add__form add__form--terminate">
          
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">  
              <div class="termination"> 
                <div class="termination__row">
                  
                  <div class="termination__column"> 
                    <div class="form__field">
                      <div class="input input--default input--readonly" v-if="!pendencyForReply">
                        {{ parseCustomerName(customerDetails) }} 
                      </div>

                      <div class="input input--default input--readonly" v-else>
                        <span v-if="pendencyReplyAgent">{{ pendencyReplyAgent.first_name }} {{ pendencyReplyAgent.last_name }} </span>
                      
                      </div>
                    </div>
                    <div class="form__field" v-if="!pendencyForReply"> 
                      <VSelect
                        class="select select--default" 
                        :class="{ 'select--error': policyHasError }"
                        :options="policies"
                        :reduce="(policy) => policy.id"
                        :getOptionLabel="
                          (policy) => `${policy.id}`
                        "
                        :placeholder="$t(`addPendencyModal.${'policyDropdown'}`)"
                        v-model="policyId"
                      />
                      <div class="form__error" v-if="policyHasError">
                        Please select a policy
                      </div>
                    </div>
                    <div class="form__field">
                      <Datepicker
                        class="input input--default" 
                        :config="statics.datePickerConfig"
                        :placeholder="$t(`addPendencyModal.${'dateDropdown'}`) "
                        v-model="deadline"
                      />
                      <!--:class="{ 'input--error': deadlineHasError }" <div class="form__error" v-if="deadlineHasError">
                        Please enter a date
                      </div> -->
                    </div>
                  </div>
                  <div class="termination__column termination__column--middle">
                    <div class="form__field">
                      <VSelect
                        class="select select--default"
                        :class="{ 'select--error': statusHasError }"
                        :options="statuses"
                        :reduce="(status) => status.id"
                        :getOptionLabel="
                          (status) => `${status.name_de}`
                        "
                        :placeholder="
                          $t(`addPendencyModal.${'statusDropdown'}`)
                        "
                        v-model="statusId"
                      /> 
                      <div class="form__error" v-if="statusHasError">
                        Please select a status
                      </div>
                    </div>
                    <div class="form__field" v-if="!pendencyForReply">  
                      <VSelect
                        class="select select--default"
                        :class="{ 'select--error': agentHasError }"
                        :options="agents"
                        :reduce="(agent) => agent.id"
                        :getOptionLabel="
                          (agent) => `${agent.first_name} ${agent.last_name}`
                        "
                        :placeholder="
                          $t(`addPendencyModal.${'beraterDropdown'}`)
                        "
                        v-model="agentId"
                      />
                      <div class="form__error" v-if="agentHasError">
                        Please select an agent
                      </div>
                    </div> 
                  </div>
                  
                  <div class="termination__column">
                    <DropFile @input="files = $event" />
                    
                  </div>
                  
                </div>
                <div class="termination__row termination__textarea__row"
                :class="pendencyForReply ? 'termination__textarea__row--edit' : ''">
                  <div class="form__field form__pendencies__textarea">
                      <textarea
                      :placeholder="
                          $t(`addPendencyModal.${'text'}`)
                        "
                        cols="30"
                        rows="10"
                        class="input input--default termination__textarea" 
                        v-model="description"
                        style="max-width: initial !important;"
                      ></textarea> 
                  </div>                  
                </div> 
              </div>
            </div>

           
          </transition>
        </div>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <div v-else>
        <button 
          v-if="!pendencyForReply && !isPendecyEditing && !isReplyEditng"
          class="modal__button modal__button--submit button button--default"
          @click="addPendency()"
        >
          <span>{{$t(`addPendencyModal.${'saveBtn'}`)}}</span>
        </button>  

        <button 
          v-if="pendencyForReply"
          class="modal__button modal__button--submit button button--default" 
          @click = "replyPendency()"
        >
          <span>{{$t(`addPendencyModal.${'replyBtn'}`)}}</span>
        </button>

        <button 
          v-if="isPendecyEditing"
          class="modal__button modal__button--submit button button--default"
          @click="editPendency()"
        >
          <span>{{$t(`addPendencyModal.${'editBtn'}`)}}</span>
        </button>
        

        <button 
          v-if="isReplyEditng"
          class="modal__button modal__button--submit button button--default"
          @click="editReplyPendency()"
        >
          <span>{{$t(`addPendencyModal.${'editReplyBtn'}`)}}</span>
        </button>
    </div>
    </div>
  </div>
</template>

<script>
import DropFile from "@/components/DropFile";
import Loading from "@/partials/Loading";
import { parseCustomerName } from "@/helpers/entityParsers";
import { mapGetters } from "vuex";
import statics from "@/statics";
export default {
  name: "AddPotentialPolicyModal",

  components: {
    DropFile,
    Loading,
  },

  data() {
    return {  
      parseCustomerName,
      formLoading: true,
      isRequesting: false,
      statics,

      policyId: "",
      agentId: "",
      customerId: "",
      statusId: "",
      deadline: "",
      description: "", 
      
      files: [],

      policyHasError: false, 
      agentHasError: false,
      customerHasError: false,
      statusHasError: false,
      deadlineHasError: false,
      descriptionHasError: false, 
    };
  },

  watch: {    
    agentId(id) {
      if (id) {
        this.agentHasError = false;
      } else {
        this.agentHasError = true;
      }
    },
    statusId(id) {
      if (id) {
        this.statusHasError = false;
      } else {
        this.statusHasError = true;
      }
    },
  },

  computed: {
    ...mapGetters([
      "pendencyForms", 
      "customerPolicies",
      "customerDetails",
      "pendencyForReply", 

      "pendencyReplyPolicyId",
      "pendencyReplyServiceId",
      "pendencyReplyAgent",

      "isPendecyEditing",

      "pendencyEditData",


      "isReplyEditng",
      "replyEditData",
      "replyEditPolicyId",

      "fromPolicyId",
      "fromPolicyAgentId",

    ]),     

  
    agents() {
      return this.pendencyForms.agents;
    },

    statuses() {
      return this.pendencyForms.statuses;
    }, 

    policies() {
      return this.customerPolicies.policies;
    }, 
  },

  async created() {
 
      this.formLoading = true;
      const customerId = this.$route.params.id;
      await this.$store.dispatch('getCustomerPolicies', customerId);
 
      await this.$store.dispatch("getPendencyForms");
      await this.$store.dispatch("getGlobalCustomer", this.customerDetails.id);
 
      this.formLoading = false;
  },


  async mounted(){ 

    if(this.isPendecyEditing){   
      this.policyId = this.pendencyEditData.policy.id;
      this.agentId = this.pendencyEditData.agent.id;
      this.statusId = this.pendencyEditData.status.id;
      this.deadline = this.pendencyEditData.deadline;
      this.description = this.pendencyEditData.description; 
    }

    if(this.isReplyEditng){    
      this.statusId = this.replyEditData.status.id;
      this.deadline = this.replyEditData.deadline;
      this.description = this.replyEditData.description; 
    }


    if(this.fromPolicyId !== null && this.fromPolicyAgentId !== null){
      this.policyId = this.fromPolicyId;
      this.agentId = this.fromPolicyAgentId;
    }
  },
 

  destroyed() {  
    this.$store.commit("setShowAddPendencyModal", false);
    this.$store.commit("setIsPendecyEditing", false); 
    this.$store.commit("setPendencyForReply", false);
    this.$store.commit("setIsReplyEditng", false);    
  

    this.$store.commit("setFromPolicyId", null);
    this.$store.commit("setPendencyReplyAgentData", null);
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddPendencyModal", false);
    },

    goBack() {
      this.closeModal();
      this.$store.commit("setShowAddActivityModal", true);
    },
 

    validateFields(callback) {
      if (this.agentId) {
        this.agentHasError = false;
      } 
      // else if(this.agentId) {
      //   this.agentHasError = true;
      // }
      else {
        this.agentHasError = true;
      }

      // if (this.customerId) {
      //   this.customerHasError = false;
      // } else {
      //   this.customerHasError = true;
      // }

      if (this.statusId) {
        this.statusHasError = false;
      } else {
        this.statusHasError = true;
      }  
     
      if (
          !this.agentHasError && 
          // !this.customerHasError && 
          !this.statusHasError 
        ) 
      {
        callback(true);
      } else {
        callback(false);
      }
    },
 
    async addPendency() {
      this.isRequesting = true;
      this.validateFields(async (isValid) => { 
        if (isValid) {  
          try {
            const pendencyId = await this.$store.dispatch('createPendencies', {
              policy_id: this.policyId,   
              agent_id: this.agentId,
              customer_id: this.customerDetails.id,
              status_id: this.statusId,
              deadline: this.deadline,
              description: this.description,
            });

            this.$store.commit("setToast", {
              display: true,
              type: "success",
              message: "Pendency created successfully.",
            });
            this.closeModal();   

            if (this.files.length > 0) {  
              const files = this.files.map((file) => file.file);
              await this.$store.dispatch("addPendencyFile", {
                pendencyId,
                files,
              });
            }
          } catch (error) {
            this.$store.commit("setToast", {
              display: true,
              type: "error",
              message: "Failed to add pendency: " + error,
            });
          } finally {
            this.isRequesting = false;
          }
        } else { 
          this.$store.commit("setToast", {
            display: true,
            type: "error",
            message: "Validation failed. Please fill all required fields.",
          });
          this.isRequesting = false;
        }
      });
    },


    editPendency(){
      this.isRequesting = true;
      this.validateFields(async (isValid) => { 
        if (isValid) {   
          this.$store.dispatch('updatePendencies', {
            id: this.pendencyEditData.id,   
            agent_id: this.agentId,
            customer_id: this.customerDetails.id,
            status_id: this.statusId,
            deadline: this.deadline,
            description: this.description,
          }).then(() => {
             
            this.$store.commit("setToast", {
                display: true,
                type: "success",
                message: "Pendency edited successfully.",
              });
            this.closeModal();   
          }).catch((error) => { 
            this.$store.commit("setToast", {
              display: true,
              type: "error",
              message: "Failed to edit pendency:" + error,
            });
          });
        } else { 
          this.$store.commit("setToast", {
            display: true,
            type: "error",
            message: "Validation failed. Please fill all required fields.",
          });
          this.isRequesting = false;
        }
      });
    },



    replyPendency(){
      this.isRequesting = true; 

      this.policyId = this.pendencyReplyPolicyId;
      this.agentId = this.pendencyReplyAgent.id;

      const pendencyId = this.pendencyReplyServiceId;



      this.validateFields(async (isValid) => { 
        if (isValid) {  
          this.$store.dispatch('replyPendencies', {
            pendency_id: pendencyId,
            policy_id: this.policyId,   
            agent_id: this.agentId,
            customer_id: this.customerDetails.id,
            status_id: this.statusId,
            deadline: this.deadline,
            description: this.description,
          }).then(() => {
             
            this.$store.commit("setToast", {
                display: true,
                type: "success",
                message: "Pendency replied successfully.",
              });
            this.closeModal();   
          }).catch((error) => { 
            this.$store.commit("setToast", {
              display: true,
              type: "error",
              message: "Failed to reply pendency:" + error,
            });
          });
        } else { 
          this.$store.commit("setToast", {
            display: true,
            type: "error",
            message: "Validation failed. Please fill all required fields.",
          });
          this.isRequesting = false;
        }
      });
    },

    editReplyPendency(){
      this.isRequesting = true;
 
      this.agentId = this.replyEditData.agent.id;
      this.validateFields(async (isValid) => { 
        if (isValid) {  
          this.$store.dispatch('updateReplyPendencies', {
            id: this.replyEditData.id,
            pendency_id: this.replyEditData.pendency_id,
            policy_id: this.replyEditPolicyId,   
            agent_id: this.agentId,
            customer_id: this.customerDetails.id,
            status_id: this.statusId,
            deadline: this.deadline,
            description: this.description,
          }).then(() => {
             
            this.$store.commit("setToast", {
                display: true,
                type: "success",
                message: "Reply updated successfully.",
              });
            this.closeModal();   
          }).catch((error) => { 
            this.$store.commit("setToast", {
              display: true,
              type: "error",
              message: "Failed to update the reply:" + error,
            });
          });
        } else { 
          this.$store.commit("setToast", {
            display: true,
            type: "error",
            message: "Validation failed. Please fill all required fields.",
          });
          this.isRequesting = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/_termination-potential-policy.scss";

.termination__row {
  border: 0;
}

.termination__textarea__row{
  margin-top: -40px;
}

.form__pendencies__textarea{
  width: 66%;
}

.termination__textarea__row--edit{
  margin-top: -100px;
}
</style>
