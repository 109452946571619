<template>
<div class="policies">
    <div class="table">
        <div class="table__header box box--default">
            <div class="table__label table__label--medium table__label--fixed">

            </div>
            <div class="table__label table__label--large"> </div>
            <div class="table__label table__label--large"> </div>
            <div class="table__label table__label--large"> </div>
            <div class="table__label table__label--large"> </div>
            <div class="table__label table__label--large"> </div>
            <div class="table__label table__label--medium"> </div>
            <div class="table__label table__label--medium table__label--more-horizontal table__label--last"></div>
        </div>

        <div class="table__body">
            <Loading v-if="detailsLoading" />
            <template v-else>
                <div v-if="!computedCalls.length" class="table__no-data">
                    <NoData :type="'calls'" />
                </div>

                <template v-else>
                    <div class="table__policies">
                        <div v-for="call in computedCalls" :key="call.id" class="call-div" :class="[
                            {'animate__animated animate__zoomOutLeft': callIsDeleting === call.id,},
                            { deleting: isCallDeleted },
                          ]">

                            <div class="call-category call-sticker">
                                <div class="icon icon__insurance"></div>
                                <p>{{call.category.name_de }}</p>
                            </div>

                            <div class="call-called call-sticker">
                                <div class="icon icon__phone"></div>
                                <p>{{ call.customer.first_name }} {{ call.customer.last_name }} </p>
                            </div>

                            <div class="call-date call-sticker">
                                <div class="icon icon__date"></div>
                                <p>{{ formatDate(call.date) }}</p>
                            </div>

                            <div class="call-time call-sticker">
                                <div class="icon icon__time"></div>
                                <p>{{ formatTime(call.time) }}</p>
                            </div>

                            <div class="call-owner">
                                <div class="call-img-wrapper" v-if="call.agent.photo">
                                    <img :src="require(`../../assets/icons/default/${call.img}`)">
                                </div>
                                <div class="call-img-wrapper no-img" v-else>
                                    <p>{{call.agent.first_name.charAt(0).toUpperCase() }} {{call.agent.last_name.charAt(0).toUpperCase() }}</p>
                                </div>
                                <p class="call-owner-name"> {{ call.agent.first_name }} {{call.agent.last_name}} </p>
                                <!-- <p class="call-owner-type">Ausgehender Anruf</p> -->
                            </div>

                            <div class="call-actions-wrapper">
                                <div class="icon icon__archive"></div> 

                                <div
                                    v-if="call.files.length"
                                    class="icon icon__pdf--active" 
                                    @click="openCallFilesModal(call)"
                                >  
                                </div>
                                <div v-else class="icon icon__pdf" @click="openCallFilesModal(call)"></div>


                                <div class="icon icon__edit" @click="showEditCallModal(call)"></div>
                                <div class="icon icon__delete" :class="{ 'icon__delete--active': activeCallId === call.id }" tabindex="-1" @focus="activeCallId = call.id" @focusout="activeCallId = null" :ref="'delete-' + call.id">
                                    <transition name="fade">
                                        <div class="delete" v-if="activeCallId == call.id">
                                            <div class="delete__text">
                                                Are you sure you want to delete this call?
                                            </div>
                                            <div class="delete__buttons">
                                                <div class="button button--confirm" @click="deleteCall(call.id)"></div>
                                                <div class="button button--cancel" @click="closeCallDeletePopup"></div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </div>

                            <div class="call-content">
                                <p>{{ call.description }}</p>
                            </div>

                            <div class="call-duration-wrapper">
                                <div class="call-title-divider"></div>
                                <div class="call-duration">
                                    <p>Gesprächsdauer: </p>
                                    <p>{{ call.duration_minutes }} <span>min</span></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </template>
            </template>
        </div>
    </div>
</div>
</template>

<script>
import Loading from "@/partials/Loading";
import NoData from "@/partials/NoData";
export default {
    name: "CallsTable",

    data() {
        return {
            callIsDeleting: "",
            activeCallId: null,
            isCallDeleted: false,
        }
    },

    methods: {
        formatTime(time) {
            let [hours, minutes] = time.split(':');

            return `${hours}:${minutes}`;
        },

        formatDate(dateString) {
            if (!dateString) return '';

            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}.${month}.${year}`;
        },

        closeCallDeletePopup(callId) {
            const deleteRef = this.$refs['delete-' + callId];
            if (deleteRef && deleteRef[0]) {
                deleteRef[0].blur();
            }
            this.activeCallId = null;
        },

        async deleteCall(id) {
            const callId = id;
            const customerId = this.$route.params.id;
            this.callIsDeleting = id;
            this.closeCallDeletePopup();
            await this.$store.dispatch("deleteCalls", {
                callId,
                customerId
            });
            this.isCallDeleted = false;
        },

        showEditCallModal(call) {
            this.$store.commit("setShowAddCallsModal", true);
            this.$store.commit("setIsCallEditing", true);
            this.$store.commit("setEditingCallData", call);
        },

        openCallFilesModal(call) {   
            this.$store.commit("setShowFilesEditModal", true);
            this.$store.commit("setFilesToEditId", call.id);
            this.$store.commit("setFilesToEdit", call.files); 
            this.$store.commit("setFilesEditMode", 'Calls'); 
        },
    },

    computed: {
        computedCalls() {
            return this.$store.getters.calls;
        }
    },

    async mounted() {
        this.detailsLoading = true;
        try {
            await this.$store.dispatch("getCalls", this.$route.params.id);
        } catch (error) {
            console.log('Error fetching Calls:', error);
        } finally {
            this.detailsLoading = false;
        }
        this.detailsLoading = false;
    },

    components: {
        Loading,
        NoData,
    },

    props: {
        detailsLoading: {
            required: true,
            type: Boolean,
        },
    },
};
</script>

<style lang="scss" scoped>
.delete {
    position: absolute;
    z-index: 3;
    background: var(--bg-1);
    right: calc(3% + 5px);
    top: 25%;
    transform: translateY(-50%);
    width: 300px;
    display: flex;
    align-items: center;
    border-radius: var(--radius-1);
    border: 1px solid var(--border-2);
    padding: 10px 20px;
    box-shadow: 0px 3px 6px var(--shadow-7);

    &__text {
        line-height: 20px;
    }

    &__buttons {
        display: flex;
    }

    .button {
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: all 0.3s ease;

        &--confirm {
            background: url("../../assets/icons/default/confirm_icon.svg");
            margin: 0 15px;

            &:hover {
                background: url("../../assets/icons/default/confirm_hover_icon.svg");
            }
        }

        &--cancel {
            background: url("../../assets/icons/default/close_icon.svg");

            &:hover {
                background: url("../../assets/icons/default/close_hover_icon.svg");
            }
        }
    }
}

.call-div {
    width: 95%;
    box-shadow: 0px 0px 15px #0000001A;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 50px;
    position: relative;
}

.call-div:first-child {
    margin-top: 50px;
}

.icon-wrapper {
    width: 24px;
    height: 24px;
}

.icon-wrapper img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.call-actions-wrapper {
    display: flex;
    gap: 15px;
    margin-top: 30px;
    align-items: center;
    justify-content: flex-end;
}

.call-title {
    width: 100%;
    margin-top: 10px;
}

.call-title p {
    color: #363636;
    font-size: 16px;
    font-weight: 600;
}

.call-title-divider {
    height: 1px;
    width: 210px;
    background-color: #EEEDED;
    margin: 10px 0;
    margin-left: auto;
}

.call-content {
    margin: 40px 0;
    margin-bottom: 20px;
    max-width: 990px;
}

.call-content p {
    font-size: 14px;
    color: #363636;
    margin-bottom: 20px;
}

.call-duration {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 14px;
    margin-right: 10px;
}

.call-duration p {
    font-size: 16px;
    color: #7F8FA4;
}

.call-duration p:last-child {
    font-weight: 800;
}

.call-status {
    width: 140px;
    position: absolute;
    top: -20px;
    left: 0;
    background-color: #FFFFFF;
    display: flex;
    border-radius: 10px;
    align-items: center;
}

.call-status-name {
    flex-grow: 1;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    height: 28px;
    margin-left: -25px;
    z-index: 1;
    background-color: #FFFFFF;
    box-shadow: 0px 8px 10px #00000026;
}

.call-status-name p {
    margin-left: 20px;
    color: #7F8FA4;
    font-size: 14px;
}

.call-status-color {
    z-index: 2;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    box-shadow: 0px 8px 10px #00000026;
}

.call-status-color-type {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.call-status-pending {
    background-color: #DB2222;
}

.call-status-in-progress {
    background-color: #FF9E0F;
}

.call-status-completed {
    background-color: #B9C2CD;
}

.call-sticker {
    position: absolute;
    top: -20px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 5px 14px;
    box-shadow: 0px 8px 10px #00000026;
    border-radius: 10px;
    width: 180px;
    background-color: #FFFFFF;
}

.call-sticker .icon {
    width: 20px;
    height: 20px;
}

.call-category {
    left: 0;
}

.call-called {
    left: 200px;
}

.call-time {
    width: 100px;
    right: 0;
}

.call-date {
    width: 150px;
    right: 120px;
}

.call-owner {
    position: absolute;
    top: 0%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -30%);
}

.call-img-wrapper {
    width: 46px;
    height: 46px;
    margin: 0 auto;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 8px 10px #00000026;
    border-radius: 50%;
}

.call-img-wrapper img {
    width: 100%;
    height: 100%;
}

.call-owner-name {
    margin: 8px 0;
    color: #363636;
    font-size: 14px;
    font-weight: bold;
}

.call-owner-type {
    color: #7F8FA4;
    font-size: 12px;
}

.no-img {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    font-weight: 800;
}
</style>
