<template>
  <div class="container">
    <LeftMenu
      :toggleLeftMenu="toggleLeftMenu"
      :showLeftMenu="showLeftMenu"
      :leftMenuLoading="leftMenuLoading"
    />
    <div class="main" :class="{ 'main--expand': !showLeftMenu }">
      <Header
        :toggleNotification="toggleNotification"
        :showNotification="showNotification"
      />
      <div class="pages">
        <transition
          mode="out-in"
          enter-active-class="animate__animated animate__fadeInDown"
          leave-active-class="animate__animated animate__fadeOutDown"
        >
          <router-view></router-view>
        </transition>
      </div>
    </div>

    <Notifications
      :toggleNotification="toggleNotification"
      :showNotification="showNotification"
    />
    <transition name="zoom" mode="out-in">
      <AddCustomer v-if="showAddCustomerModal" />
      <AddFamilyMember v-if="showAddFamilyMemberModal" />
      <AddSearchFamilyMember v-if="showAddSearchFamilyMemberModal" />
      <AddCompanyModal v-if="showAddCompanyModal" />
      <AddPrivateModal v-if="showAddPrivateModal" />
      <EditPrivateEmailModal v-if="showEditPrivateEmailModal"/>
      <EditCompanyEmailModal v-if="showEditCompanyEmailModal"/>
      <AddUserModal v-if="showAddUserModal" />
      <AddCustomerServices v-if="showAddCustomerServicesModal" />
      <AddPolicyModal v-if="showAddPolicyModal" />
      <AddActivityModal v-if="showAddActivityModal" />
      <AddPendencyModal v-if="showAddPendencyModal"/> 
      <AllFilesModal v-else-if="showFilesEditModal"/>
      <AddNotesModal v-if="showAddNotesModal"/>
      <AddCallsModal v-if="showAddCallsModal"/>
      <PolicyFilesModal v-if="showPolicyFilesModal" />
      <TerminationModal v-if="showTerminationModal" />
      <AddModelModal v-if="showAddModelModal" />
      <AddCompanyContactModal v-if="addCompanyContactModal" />
      <AddRoleModal v-if="showAddRoleModal" />
      <AddPermissionModal v-if="showAddPermissionModal" />
      <AddSaleCategoryModal v-if="showAddSaleCategoryModal" />
      <AddSaleCategoryCompanyModal v-if="showAddSaleCategoryCompanyModal" />
      <AddPotentialPolicyModal v-if="showAddPotentialPolicyModal" />
      <SearchPotentialCustomerModal v-if="showSearchPotentialCustomerModal" />
      <PolicyProvisionModal v-if="showPolicyProvisionModal" />
      <AddAccidentModal v-if="showAddAccidentModal" />
      <AddInvoiceModal v-if="showAddInvoiceModal" />
      <AddTransactionModal v-if="showAddTransactionModal" />
      <PayoutReviewModal v-if="showPayoutReviewModal" />
      <DeleteModal v-if="deleteModal.display" />
      <ResetPasswordModal v-if="showResetPasswordModal" />
      <AddTeamModal v-if="showAddTeamModal" />
      <AddTeamTaxModal v-if="showAddTeamTaxModal" />
      <PolicyProvisionImportModal v-if="showPolicyProvisionImportModal" />
    </transition>
    <transition name="fade">
      <div
        class="container__blur"
        v-if="
          showAddCompanyModal ||
            showAddPrivateModal ||
            showEditPrivateEmailModal ||
            showEditCompanyEmailModal ||
            showAddUserModal ||
            showAddCustomerModal ||
            showAddSearchFamilyMemberModal ||
            showAddFamilyMemberModal ||
            showAddCustomerServicesModal ||
            showAddPolicyModal ||
            showAddActivityModal ||
            showAddPendencyModal || 
            showFilesEditModal ||
            showAddNotesModal ||
            showAddCallsModal ||
            showPolicyFilesModal ||
            showTerminationModal ||
            showAddModelModal ||
            addCompanyContactModal ||
            showAddRoleModal ||
            showAddPermissionModal ||
            showAddSaleCategoryModal ||
            showAddSaleCategoryCompanyModal ||
            showAddPotentialPolicyModal ||
            showSearchPotentialCustomerModal ||
            showPolicyProvisionModal ||
            showAddAccidentModal ||
            showAddInvoiceModal ||
            showAddTransactionModal ||
            showPayoutReviewModal ||
            deleteModal.display ||
            showResetPasswordModal ||
            showAddTeamModal || showAddTeamTaxModal
            || showPolicyProvisionImportModal
        "
      ></div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/partials/Header";
import LeftMenu from "@/partials/LeftMenu";
import Notifications from "@/partials/Notifications";
import AddCustomer from "@/components/AddCustomer";
import DeleteModal from "@/components/DeleteModal";
import AddCompanyModal from "@/components/AddCompanyModal";
import AddPrivateModal from "@/components/AddPrivateModal";
import EditPrivateEmailModal from "@/components/EditPrivateEmailModal";
import EditCompanyEmailModal from "@/components/EditCompanyEmailModal";
import AddUserModal from "@/components/AddUserModal";
import AddCustomerServices from "@/components/AddCustomerServices";
import AddPolicyModal from "@/components/AddPolicyModal";
import AddActivityModal from "@/components/AddActivityModal"; 
import AddPendencyModal from "@/components/AddPendencyModal";

import AllFilesModal from "@/components/AllFilesModal";
import AddNotesModal from "@/components/AddNotesModal";
import AddCallsModal from "@/components/AddCallsModal";
import PolicyFilesModal from "@/components/PolicyFilesModal";
import TerminationModal from "@/components/TerminationModal";
import AddModelModal from "@/components/AddModelModal";
import AddCompanyContactModal from "@/components/AddCompanyContactModal";
import AddRoleModal from "@/components/AddRoleModal";
import AddPermissionModal from "@/components/AddPermissionModal";
import AddSaleCategoryModal from "@/components/AddSaleCategoryModal";
import AddPotentialPolicyModal from "@/components/AddPotentialPolicyModal";
import SearchPotentialCustomerModal from "@/components/SearchPotentialCustomerModal";
import PolicyProvisionModal from "@/components/PolicyProvisionModal";

import AddAccidentModal from "@/components/AddAccidentModal";
import AddInvoiceModal from "@/components/AddInvoiceModal";
import AddTransactionModal from "@/components/AddTransactionModal";
import PayoutReviewModal from "@/components/PayoutReviewModal";
import getPermissions from "@/helpers/getPermissions";
import ResetPasswordModal from "@/components/ResetPasswordModal";
import AddTeamModal from "@/components/AddTeamModal";
import AddTeamTaxModal from "@/components/AddTeamTaxModal";
import AddFamilyMember from "@/components/AddFamilyMember";
import AddSearchFamilyMember from "@/components/AddSearchFamilyMember";
import AddSaleCategoryCompanyModal from "@/components/AddSaleCategoryCompanyModal";
import PolicyProvisionImportModal from "@/components/PolicyProvisionImportModal";
//import statics from "@/statics";
export default {
  name: "Main",

  components: {
    PolicyProvisionImportModal,
    AddSaleCategoryCompanyModal,
    AddSearchFamilyMember,
    AddFamilyMember,
    LeftMenu,
    Header,
    Notifications,
    AddCustomer,
    DeleteModal,
    AddCompanyModal,
    AddPrivateModal,
    EditPrivateEmailModal,
    EditCompanyEmailModal,
    AddUserModal,
    AddCustomerServices,
    AddPolicyModal,
    AddActivityModal,
    AddPendencyModal,
    AddNotesModal,
    AddCallsModal,
    PolicyFilesModal,
    TerminationModal,
    AddModelModal,
    AddCompanyContactModal,
    AddRoleModal,
    AddPermissionModal,
    AddSaleCategoryModal,
    AddPotentialPolicyModal,
    SearchPotentialCustomerModal,
    PolicyProvisionModal,
    AddAccidentModal,
    AddInvoiceModal,
    AddTransactionModal,
    PayoutReviewModal,
    ResetPasswordModal,
    AddTeamModal,
    AddTeamTaxModal, 
    AllFilesModal,
  },

  data() {
    return {
      showLeftMenu: true,
      showNotification: false,
      notificationsInterval: null,
      leftMenuLoading: false,
    };
  },

  computed: {
    ...mapGetters([
      "showAddCompanyModal",
      "showAddPrivateModal",
      "showEditPrivateEmailModal",
      "showEditCompanyEmailModal",
      "showAddUserModal",
      "showAddCustomerModal",
      "showAddSearchFamilyMemberModal",
      "showAddFamilyMemberModal",
      "showAddSearchFamilyMemberModal",
      "showAddCustomerServicesModal",
      "showAddPolicyModal",
      "showAddPendencyModal",
      "showAddNotesModal",
      "showAddCallsModal",
      "showAddActivityModal",
      "showPolicyFilesModal",
      "showTerminationModal",
      "showAddModelModal",
      "addCompanyContactModal",
      "showAddRoleModal",
      "showAddPermissionModal",
      "showAddSaleCategoryModal",
      "showAddSaleCategoryCompanyModal",
      "showAddPotentialPolicyModal",
      "showSearchPotentialCustomerModal",
      "showPolicyProvisionModal",
      "showAddAccidentModal",
      "showAddInvoiceModal",
      "showAddTransactionModal",
      "showPayoutReviewModal",
      "loggedUser",
      "deleteModal",
      "showResetPasswordModal",
      "showAddTeamModal",
      "showAddTeamTaxModal",
      "showPolicyProvisionImportModal",
   
      "showFilesEditModal"
    ]),
  },

  async mounted() {
    this.showLeftMenu = false;
    this.leftMenuLoading = true;
    await this.startFetchingNotifications();
    this.loadRolePermissions();
    this.setLanguage();
    this.leftMenuLoading = false;
    this.showLeftMenu = true;
  },

  destroyed() {
    clearInterval(this.notificationsInterval);
  },

  methods: {
    setLanguage() {
      const { locale } = this.loggedUser.settings || {
        locale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
      };
      this.$store.commit("setLanguage", locale);
      this.$root.$i18n.locale = locale;
      window.localStorage.setItem("language", locale);
    },
    toggleLeftMenu(state) {
      this.showLeftMenu = state;
    },

    toggleNotification(state) {
      this.showNotification = state;
    },

    loadRolePermissions() {
      this.$store.commit("setRolePermissions", getPermissions());
    },

    async startFetchingNotifications() {
      await this.$store.dispatch("getNotifications");
      //this.notificationsInterval = setInterval(async () => {
       // await this.$store.dispatch("getNotifications");
     // }, statics.fetchNotificationTime);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  position: relative;
  overflow: hidden;

  .main {
    width: calc(100% - 300px);
    transition: width 0.2s ease;

    &--expand {
      width: calc(100% - 70px);
    }

    .pages {
      height: calc(100% - 70px);
      background: var(--bg-17);
      position: relative;
      padding: 20px;
      overflow: auto;
    }
  }

  .fade-leave-active {
    transition-delay: 0.3s;
  }

  &__blur {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--modal-bg-1);
    backdrop-filter: blur(12px);
    z-index: 98;
  }
}
</style>
